import React from "react";
import styled from "styled-components";

const PaymentCheckbox = (props) => (
  <CheckBoxContainer isSelected={props.isSelected} onClick={props.onClick}>
    {typeof props.amount === "number" && "€ "}
    {props.amount}
  </CheckBoxContainer>
);

const CheckBoxContainer = styled.div`
  display: flex;
  min-width: 62px;
  min-height: 41px;
  cursor: pointer;
  border-radius: 3px;
  background-color: ${(props) => (props.isSelected ? "#007ec9" : "#333333")};
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  align-items: center;
  margin-right: 10px;
  margin-top: 10px;
  justify-content: center;
`;

const Label = styled.p`
  font-family: "Nunito Sans";
  font-size: 16px;
  letter-spacing: 0.2px;
  color: #ffffff;
`;

export default PaymentCheckbox;
