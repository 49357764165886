import React from "react";
import styled from "styled-components";
import StepWizard from "react-step-wizard";
import { useState } from "react";
import MainLayout from "../layout";
import addToMailchimp from "gatsby-plugin-mailchimp";
import PaymentCheckbox from "../components/PaymentCheckbox";
import Foundation from "../images/mua-heart.svg";
import MayaHome from "../images/home-maya.svg";
import Calendar from "../images/Calendar.svg";
import Once from "../images/once.svg";
import TextInput from "../components/TextInput";
import { FormattedMessage, useIntl } from "gatsby-plugin-intl";
import { Checkbox, useCheckboxState } from "pretty-checkbox-react";
import { useActions } from "react-api-data";
import {
  StringParam,
  useQueryParam,
  BooleanParam,
  NumberParam,
} from "use-query-params";

const amounts = [10, 20, 40, 80, "anders"];

const emailValidation = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

const Step1 = (props) => {
  return (
    <Step>
      <Title>
        <FormattedMessage id="wantToSupport" />
      </Title>
      <PickContainer>
        <RadioContainer>
          <RadioButton
            onClick={() => {
              props.setType("foundation");
              props.nextStep();
            }}
            isSelected={props.type === "foundation"}
          >
            <RadioImg src={Foundation} />
          </RadioButton>
          <PickLabel>
            <FormattedMessage id="projects" />
          </PickLabel>
        </RadioContainer>

        <RadioContainer>
          <RadioButton
            onClick={() => {
              props.setType("home");
              props.nextStep();
            }}
            isSelected={props.type === "home"}
          >
            <RadioImg src={MayaHome} />
          </RadioButton>
          <PickLabel>
            <FormattedMessage id="mayaHome" />
          </PickLabel>
        </RadioContainer>
      </PickContainer>
    </Step>
  );
};

const Step2 = (props) => {
  return (
    <>
      <Step>
        <Title>
          <FormattedMessage id="donateAmount" />
        </Title>
        <PickContainer>
          <RadioContainer>
            <RadioButton
              onClick={() => {
                props.setRecurring(false);
              }}
              isSelected={!props.recurring}
            >
              <RadioImg src={Calendar} />
            </RadioButton>
            <PickLabel>
              <FormattedMessage id="monthly" />
            </PickLabel>
          </RadioContainer>

          <RadioContainer>
            <RadioButton
              onClick={() => {
                props.setRecurring(true);
              }}
              isSelected={props.recurring}
            >
              <RadioImg src={Once} />
            </RadioButton>
            <PickLabel>
              <FormattedMessage id="once" />
            </PickLabel>
          </RadioContainer>
        </PickContainer>
        <AmountContainer>
          {amounts.map((amount) => {
            return (
              <PaymentCheckbox
                amount={amount}
                key={amount}
                isSelected={props.amount === amount}
                onClick={() => {
                  if (amount !== "anders") {
                    props.setAmount(amount);
                    props.setOther(false);
                  } else {
                    props.setOther(true);
                  }
                }}
              />
            );
          })}
        </AmountContainer>
        {props.other && (
          <InputContainer>
            <StyledTextInput
              onChange={(e) => {
                props.setAmount(+e.target.value);
              }}
              value={props.amount}
              type="number"
              validation={false}
            />
          </InputContainer>
        )}
        <ButtonContainer>
          <ButtonConfirm onClick={() => props.nextStep()}>
            <FormattedMessage id="nextStep" />
          </ButtonConfirm>
          <Previous onClick={() => props.previousStep()}>
            <FormattedMessage
              id="previousStep"
              defaultMessage="Previous Step"
            />
          </Previous>
        </ButtonContainer>
      </Step>
    </>
  );
};

const Step3 = (props) => {
  const actions = useActions();
  const intl = useIntl();

  const disabled = !emailValidation(props.email);
  return (
    <>
      <Step>
        <Title>
          <FormattedMessage id="lastDetails" />
        </Title>
        <SecondInputContainer>
          <StyledTextInput
            onChange={(e) => {
              props.setName(e.target.value);
            }}
            text={intl.formatMessage({ id: "name" })}
            value={props.name}
            validation={false}
          />
        </SecondInputContainer>
        <SecondInputContainer>
          <StyledTextInput
            onChange={(e) => {
              props.setEmail(e.target.value);
            }}
            text={intl.formatMessage({ id: "email" })}
            value={props.email}
            validation={emailValidation}
          />
        </SecondInputContainer>
        <StyledCheckbox name="newsLetter" color="info" {...props.newsLetter}>
          <FormattedMessage id="stayUpdated" />
        </StyledCheckbox>
        <ButtonContainer>
          <DonateText>
            <FormattedMessage id="donate" />{" "}
            {!props.recurring ? (
              <FormattedMessage id="monthly" />
            ) : (
              <FormattedMessage id="once" />
            )}{" "}
            €{props.amount} <FormattedMessage id="to" />{" "}
            {props.type === "foundation" ? (
              <FormattedMessage id="mayaNl" />
            ) : (
              <FormattedMessage id="mayaHome" />
            )}
          </DonateText>
          <ButtonConfirm
            disabled={disabled}
            onClick={() => {
              props.newsLetter.state &&
                addToMailchimp(
                  props.email,
                  {},
                  props.type === "foundation"
                    ? "https://mua-nl.us16.list-manage.com/subscribe/post?u=c9a128134e1045a942d024fd4&amp;id=8bfdfd7d34&amp;f_id=00056de0f0"
                    : ""
                );
              !props.recurring
                ? actions.perform(
                    props.type === "foundation"
                      ? "mollieCustomer"
                      : "mayahomeCustomer",
                    {},
                    {
                      metaData: props.amount.toFixed(2),
                      name: props.name,
                      email: props.email,
                      locale: intl.locale,
                    }
                  )
                : actions.perform(
                    props.type === "foundation"
                      ? "mollieSinglePayment"
                      : "mayahomeDonation",
                    {},
                    { amount: props.amount.toFixed(2), locale: intl.locale }
                  );
            }}
          >
            <FormattedMessage id="donate" defaultMessage="Doneren" />
          </ButtonConfirm>
          <Previous onClick={() => props.previousStep()}>
            <FormattedMessage id="previousStep" defaultMessage="PreviousStep" />
          </Previous>
        </ButtonContainer>
      </Step>
    </>
  );
};

// const Step5 = (props) => {
//   return (
//     <>
//       <Previous onClick={() => props.previousStep()}>
//         <ChevronIcon src={Chevron} /> previous
//       </Previous>
//       <Step>
//         <Title>I agree to:</Title>

//         <ButtonConfirm onClick={() => props.nextStep()}>Confirm</ButtonConfirm>
//       </Step>
//     </>
//   );
// };

const DonationPage = ({ pageContext }) => {
  const [type, setType] = useQueryParam("type", StringParam);
  const [recurring, setRecurring] = useQueryParam("recurring", BooleanParam);
  const [amount, setAmount] = useQueryParam("amount", NumberParam);
  const [other, setOther] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const newsLetter = useCheckboxState();
  return (
    <MainLayout>
      <Container>
        <StyledStepWizard isHashEnabled initialStep={1}>
          <Step1 hashKey={"step1"} type={type} setType={setType}></Step1>
          <Step2
            hashKey={"step2"}
            recurring={recurring}
            setRecurring={setRecurring}
            amount={amount}
            setAmount={setAmount}
            other={other}
            setOther={setOther}
          ></Step2>
          <Step3
            hashKey={"step3"}
            email={email}
            setEmail={setEmail}
            name={name}
            amount={amount}
            type={type}
            newsLetter={newsLetter}
            recurring={recurring}
            setName={setName}
          />
        </StyledStepWizard>
      </Container>
    </MainLayout>
  );
};

const Container = styled.div`
  max-width: 100vw;
  height: 100vh;
  background: url("http://res.cloudinary.com/djhc9jhiq/image/upload/v1521405611/Sfeer_shot_twee_bjtyag.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
`;

const StyledStepWizard = styled(StepWizard)`
  max-width: 95%;
  position: relative;
`;

const Step = styled.div`
  width: 420px;

  background-color: #fef4ea;
  border-radius: 3px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 1px 0 rgba(109, 109, 109, 0.5);
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 95%;
    margin: auto;
    max-width: 95%;
  }
`;

const InputContainer = styled.div`
  margin-top: 16px;
  width: 150px;
`;

const StyledCheckbox = styled(Checkbox)`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  color: #333;
  font-family: "Nunito Sans";
  label {
    margin-left: 4px;
  }
`;

const SecondInputContainer = styled.div`
  margin-top: 16px;
  width: 100%;
`;
const Title = styled.h2`
  font-family: "Nunito Sans";
  font-size: 30px;
  font-weight: 600;
  margin: 0px;
  width: 100%;
  margin-bottom: 24px;
  letter-spacing: 0.3px;
  text-align: center;
  color: #333333;
`;

const Previous = styled.div`
  width: 100%;
  cursor: pointer;
  font-family: "Nunito Sans";
  color: #000aff;
  margin-top: 16px;
  text-decoration: underline;
`;

const PickContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

const AmountContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 16px;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const RadioImg = styled.img`
  width: 30px;
  height: 30px;
`;

export const RadioContainer = styled.div`
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const PickLabel = styled.p`
  font-family: "Nunito Sans";
  font-size: 16px;
  margin-top: 5px;
  font-weight: 900;
  letter-spacing: 0.2px;
  color: #333333;
  text-align: center;
`;

export const RadioButton = styled.div`
  position: relative;
  display: flex;
  min-width: 25px;
  min-height: 25px;
  width: 53px;
  height: 53px;
  cursor: pointer;
  text-align: center;
  align-content: center;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 12px;
  color: #fff;
  background-color: ${(props) =>
    props.isSelected && !props.hasBorder ? "#007ec9" : "#333333"};
  line-height: 25px;
`;

const StyledTextInput = styled(TextInput)`
  width: 50px;
  margin-top: 26px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  bottom: 24px;
  margin-top: 70px;
  flex-direction: column;
`;

export const ButtonConfirm = styled.button`
  opacity: ${(props) => (props.disabled ? "0.7" : "1")};
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background-color: #f58320;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 36px;
  cursor: pointer;
  color: white;
  letter-spacing: 0.2px;
  text-align: center;
`;

const DonateText = styled.p`
  font-family: "Nunito Sans";
  color: #333;
  width: 100%;
  text-align: center;
  font-style: italic;
  font-size: 14px;
`;

export default DonationPage;
